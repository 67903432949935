import request from '@/utils/request'



// 查询交易单列表
export function listPayment(query) {
  return request({
    url: '/order/payment/list',
    method: 'get',
    params: query
  })
}

// 查询交易单分页
export function pagePayment(query) {
  return request({
    url: '/order/payment/page',
    method: 'get',
    params: query
  })
}

export function paymentRefund(data) {
  return request({
    url: '/order/refund/refund/direct',
    method: 'post',
    data: data
  })
}